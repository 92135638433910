import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "@components/seo"
import Layout from "@components/layout"
import Footer from "@components/Footer/Footer"
import VenturesQuote from "@components/Quotes/VenturesQuote"
import Parchment from "@static/icons/parchment.svg"

export const query = graphql`
  query VenturesPageQuery($id: Int) {
    strapiVenturespage(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      description
    }
    allStrapiVenture(sort: {fields: [order], order: ASC}) {
      edges {
        node {
          id
          name
          content
          url
          label
          logoURL
          order
        }
      }
    }
  }
`

const VenturesPage =  ({ data }) => {
  const venturesPage = data.strapiVenturespage
  const venturesList = data.allStrapiVenture
  const title = venturesPage.title ? venturesPage.title : ""
  const description = venturesPage.description ? venturesPage.description : ""
  const subtitle = venturesPage.subtitle ? venturesPage.subtitle : ""

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div id="ventures" className="grid-container page">
            <SEO
              title={title}
              description={description}
            />
            <Parchment />
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <VenturesQuote />
            <section className="ventures-section">
              {venturesList.edges.map(item => {
                const venture = item.node
                const ventureID = venture.id
                const ventureName = venture.name ? venture.name : ''
                const ventureContent = venture.content ? venture.content : ''
                const ventureUrl = venture.url ? venture.url : ''
                const ventureExternalLinkLabel = venture.label ? venture.label : ''

                let logo
                const logoAltText = ventureName + ' logo'
                if (venture.logoURL) {
                  logo = (
                    <img src={venture.logoURL} alt={logoAltText} />
                  )
                } else {
                  logo = (
                    <img src="https://via.placeholder.com/500x250/0000FF/808080" alt={logoAltText} />
                  )
                }

                return (
                  <div className="grid-block align-center venture" key={ventureID}>
                    <div className="venture-logo-wrapper">
                      <a
                        href={ventureUrl}
                        title={ventureName}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {logo}
                      </a>
                    </div>
                    <div className="venture-details-wrapper">
                      <ReactMarkdown children={ventureContent} />
                      <a
                        href={ventureUrl}
                        title={ventureName}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {ventureExternalLinkLabel}
                      </a>
                    </div>
                  </div>
                )
              })}
            </section>
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default VenturesPage