import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Quote from "./Quote"

const VenturesQuote = () => {
  const data = useStaticQuery(graphql`
    {
      strapiQuote(author: {eq: "Grace Hopper"}) {
        id
        quote
        author
      }
    }
  `)

  const quoteData = data.strapiQuote
  const quote = quoteData.quote ? quoteData.quote : ""
  const author = quoteData.author ? quoteData.author : ""

  return (
    <Quote
      quote={quote}
      author={author}
    />
  )
}

export default VenturesQuote